import IntegrationLogs from "./IntegrationLogs";

export default class LogTabs {
    constructor(containerId) {
        this.container = document.getElementById(containerId);
        if (!this.container) {
            throw new Error(`Container with id ${containerId} not found`);
        }

        this.tabButtons = this.container.querySelectorAll(".tab-button");
        this.tabContents = this.container.querySelectorAll(".tab-content");
        this.mid = this.container.dataset.mid;

        this.initTabs();
    }

    initTabs() {
        this.tabButtons.forEach(button => {
            button.addEventListener("click", () => this.handleTabClick(button));
        });
    }

    handleTabClick(button) {
        const targetTab = button.dataset.tab;
        const targetContent = this.container.querySelector(`#${targetTab}`);

        // Check if the content is already visible
        const isContentVisible = !targetContent.classList.contains("hidden");

        // Hide all tab contents and remove active class from all buttons if content is not visible
        if (!isContentVisible) {
            this.tabContents.forEach(content => content.classList.add("hidden"));
            this.tabButtons.forEach(btn => btn.classList.remove("bg-gray-300", "font-bold", "active"));
        }

        // Toggle the clicked tab button and its content
        if (isContentVisible) {
            button.classList.remove("bg-gray-300", "font-bold", "active");
            targetContent.classList.add("hidden");
        } else {
            button.classList.add("bg-gray-300", "font-bold", "active");
            targetContent.classList.remove("hidden");

            // Load data if not already loaded
            if (!targetContent.dataset.loaded) {
                this.loadDataForTab(targetTab, targetContent);
                targetContent.dataset.loaded = 'true';
            }
        }
    }

    async loadDataForTab(tabId, contentElement) {
        if (tabId === 'integration-logs') {
            const response = await fetch(`/dashboard/integration-logs?mid=${this.mid}`);
            const data = await response.json();
            this.data = data.result; // Save the fetched data
            new IntegrationLogs(contentElement, this.data);
        }
    }
}
