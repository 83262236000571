export default class CreateQuote {
    constructor() {
        // listen to change from select with id product
        document.getElementById('product').addEventListener('change', function (event) {
            let product = event.target.value;

            let selectedDiscount = {
                starter: '10',
                pro: '15',
                business: '20'
            };

            // Get the discount select element
            let discount = document.getElementById('discount');

            // Set the selected value based on the product
            if (selectedDiscount[product]) {
                discount.value = selectedDiscount[product];
            }
        });
    }
}