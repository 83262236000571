export default class UserTierChange {
    constructor() {
        this.setTierButton = document.querySelector('#set-tier-button');
        if (!this.setTierButton) {
            return;
        }
        this.init();
    }

    init() {
        // Add event listener to the button
        this.setTierButton.addEventListener('click', this.confirmBox.bind(this));
    }

    confirmBox(event) {

        let setUserTierSelect = document.querySelector('#setUserTierSelect');

        let currentTierId = setUserTierSelect.dataset.tierid;
        let currentTierName = this.getTierNameById(currentTierId);
        let newTierId = document.querySelector('#setUserTierSelect').value;
        let newTierName = this.getTierNameById(newTierId);

        const confirmed = confirm(`Are you sure you want to change the user's tier from ${currentTierName} to ${newTierName}?`);
        if (!confirmed) {
            event.preventDefault(); // Prevent form submission if not confirmed
        }
    }

    getTierNameById(tierId) {
        let tierName = '';
        switch (tierId) {
            case '1':
                tierName = 'Free';
                break;
            case '2':
                tierName = 'Starter';
                break;
            case '3':
                tierName = 'Pro';
                break;
            case '4':
                tierName = 'Business';
                break;
            case '5':
                tierName = 'Enterprise';
                break;
            default:
                console.error('Invalid tier ID');
        }
        return tierName;
    }
}
