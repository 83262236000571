export default class AgentDebugLog {
    constructor() {
        this.loadButton = document.getElementById('loadAgentDebugLogsButton');
        this.resultsContainer = document.getElementById('agent-debug-results');
        this.searchInput = document.getElementById('agentDebugLogSearch');
        this.resultCount = document.getElementById('result-count');
        this.data = []; // Store the fetched data here

        if (this.loadButton) {
            this.loadButton.addEventListener('click', this.loadLogs.bind(this));
        }

        if (this.searchInput) {
            this.searchInput.addEventListener('input', this.filterLogs.bind(this));
        }
    }

    async loadLogs() {
        const mid = this.loadButton.getAttribute('data-mid');
        const response = await fetch(`/dashboard/agent-debug-logs?mid=${mid}`);
        const data = await response.json();
        this.data = data.result; // Save the fetched data

        // Initially display all results
        this.displayLogs(this.data);
    }

    filterLogs() {
        const search = this.searchInput.value.trim().toLowerCase();
        const filteredData = this.data.filter(item => {
            const jsonData = JSON.parse(item.data);
            return this.containsSearchTerm(jsonData, search);
        });

        // Display filtered results
        this.displayLogs(filteredData);
    }

    displayLogs(logs) {
        // Clear previous results
        this.resultsContainer.innerHTML = '';

        // Check if logs empty
        if (logs.length === 0) {
            alert('No logs found');
        }

        // Create list items for each data item
        logs.forEach(item => {
            const li = document.createElement('li');
            const pre = document.createElement('pre');
            pre.className = 'json-data';
            pre.textContent = JSON.stringify(JSON.parse(item.data), null, 2);
            li.appendChild(pre);
            this.resultsContainer.appendChild(li);
        });

        this.resultCount.textContent = logs.length;
    }

    containsSearchTerm(data, searchTerm) {
        if (typeof data === 'object' && data !== null) {
            return Object.values(data).some(value =>
                this.containsSearchTerm(value, searchTerm)
            );
        }
        if (typeof data === 'string') {
            return data.toLowerCase().includes(searchTerm);
        }
        return false;
    }
}