export default class CreateQuote {
    constructor() {
        let dashboardSearchUsersButton = document.querySelector('#dashboard-search-users-button');
        if (!dashboardSearchUsersButton) {
            return;
        }
        this.init();
    }

    init() {
        // add event listener to the button
        document.querySelector('#dashboard-search-users-button').addEventListener('click', this.searchUsers);
    }

    searchUsers() {
        let searchInput = document.querySelector('#dashboard-search-users-input');
        if (!searchInput.value) {
            alert('Please enter a search query');
            return;
        }
        // redirect to the search page
        window.location.href = '/dashboard/paginated-search?query=' + searchInput.value;
    }
}